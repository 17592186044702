//comment todos
import { ArticuleOneColumn, ArticuleTwoColumns } from "../typeContentMod/typeArticules"
//No pueden estar en el mismo componente

function ContentCenterHigLigth(props) {
    const {contentArticules} = props
    return(
        <div className="articulesContent">
            <ArticuleTwoColumns 
                contentArticules = {contentArticules}
            />
            <ArticuleOneColumn
                contentArticules = {contentArticules}
            />            
        </div>
    )
}

export default function ContentCenterManager(props){
    const { dtContentCenter: { webContent: { slider } } } = props

    return (
        <ContentCenterHigLigth
                contentArticules={slider}
        />
    )
}