import HeadContentSlide from "./headDataSlide"
import HeadLogoMenuNav from "./headMainMenu"


const HaedAllComponent = (props) => {
	const { listDataMenuHead, listDtSlider } = props
    return (			
		<div className="headerGridAll">
			<HeadLogoMenuNav dataForHeadMain={listDataMenuHead}/>
			<HeadContentSlide dtSlider={listDtSlider}/>
		</div>
    )
}
export default HaedAllComponent