import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import {CirclesWithBar} from "react-loader-spinner"
import HeadComponent from "./head"
import ContentCenterManager from "./content"
import ConstructionPage from "./construction"
import "./components.css"
import FooterContent from "./footer"
import { callGetAllData } from "./backCalls"
import { getContentWeb } from "../slices/contentSlice"
import { getStatusApp } from "../slices/statusAppSlice"


const functionStatusSite = (dataStatus) => {
    const statusApp = dataStatus?.status || 'true'
    const statusSite = statusApp.status
    const statusConstruction = statusSite.filter((statusCode, index) => {
        const statusApp = statusCode.active === true ? statusCode : null
        return statusApp
    })

    return statusConstruction
    
}
export default function HomePage() {
    const [ loading, setLoading ] = useState(true)
    const contentWebData = useSelector(state => state.content)
    const stateApp = useSelector(state => state.statusApp)
    const dispatch = useDispatch()
    useEffect(()=>{
        const getData = async() => {
            try {
                const response = await callGetAllData()
                dispatch(getContentWeb(response.data))
                const statusAppInfo = functionStatusSite(response.data)
                dispatch(getStatusApp(statusAppInfo))
                setLoading(false)
            } catch (error) {
                console.log('Error get data-----', error)
            }
        }
        getData()
        
    },[dispatch])
    let tempoUnderStatus = { "active": false, "statusDev": "construcción" } //Only for development env
    const statusConstruction =  process.env.NODE_ENV === "development" ? tempoUnderStatus : stateApp['statusApp'][0]

    return (
        <div className="ComponentStyle">
            {
                loading ? (
                    <div className="containerLoader">
                        <div className="contentLoader">
                            <CirclesWithBar 
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        </div>
                    </div>
                ) : 
                    statusConstruction.active ? (
                        <ConstructionPage statusInfo={statusConstruction}/>
                    ) : (
                        <>
                            <HeadComponent dtHeadComponent={contentWebData}/>
                            <ContentCenterManager dtContentCenter={contentWebData} />
                            <FooterContent />
                        </>
                    )
            }
        </div>
    )
}

