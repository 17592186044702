import "./footer.css"
function Footer(){
    return (
        <div className="footerClass">
            <div>
                Footer
            </div>
        </div>
    )
}

export default function FooterContent() {
    return <Footer />
}