import React from "react";
import './construction.css'

const ConstructionPage = (props) => {
    const { statusInfo } = props
    return (
      <div className="container">
        <div className="content">
          <h1>¡Sitio web en {statusInfo.statusDev}!</h1>
          <p>Estamos trabajando duro para ofrecerte una mejor experiencia. No deberia estar</p>
          <p>Vuelve pronto para ver nuestro nuevo sitio web.</p>
          <div className="progress-bar"></div>
          <div className="social-links">
            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
      </div>
    );

}

export default ConstructionPage