import { createSlice } from '@reduxjs/toolkit'

export const statusAppSlice = createSlice({
    name: 'statusApp',
    initialState: {
        statusApp:{}
    },
    reducers: {
        getStatusApp: (state, action) => {
            state.statusApp = action.payload            
        }
        //Estudiar un poco mas
    }
})

export const { getStatusApp } = statusAppSlice.actions

export default statusAppSlice.reducer