import HomePage from "./components/index"
import "./App.css"

function App() {
  return (
    <div className="AppDocument">
      <HomePage />
    </div>
  );
}

export default App;
