//Implemantar funcion para hacer scrroll automatico a la iquierda o derecha o que  sea loop
//Buscar una mejor implmentacion mejor de este modulo

const ProcessCarouselData = (props) => {
	const { dtSlider: ListData } = props
	const sliderCountActive = ListData.filter((data) => data.inSlider === true)
	const sliderCloumnsToShow = sliderCountActive.length
	return (
		<>
			<div className="slider">		
				<div className="images" style={{gridTemplateColumns: 'repeat(' + sliderCloumnsToShow + ', 100%)'}}>
				{
					ListData.map((dataCarousel) => {
						if(dataCarousel.inSlider && dataCarousel.isActive){
							return(
								<div className="fieldArticulo" key={dataCarousel.index}>	
									<div className="resumenArticule">
										<h2>{dataCarousel.titleLeft}</h2>
										<h4>{dataCarousel.subTitle}</h4>
										<p>{dataCarousel.contentArticule}</p>
										<a href={`#${dataCarousel.index}`}>{dataCarousel.linkInside}</a>
									</div>
									<div>
										<img src={dataCarousel.Image} alt="text" key={dataCarousel.index} />
									</div>
							</div>

							)
						}
						return null
					})
				}
				</div>
			</div>
		</>
	) 
}
const HeadContentSlide = (props) => {
	const { dtSlider } = props
	return (
		<div className="headClass">
			<ProcessCarouselData dtSlider={dtSlider} />
		</div>
	)
}

export default HeadContentSlide