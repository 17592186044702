import { CLASSIMGLOGOHEADLEFT, CLASSMAINRIGHT,
         CLASSTOTEXTHEADLEFTUP } from "./headConfig"
const fncMainContentMenu = (dataForMain, classToApply) => {
    return(
        dataForMain.map((mainHead,index) => 
            { return mainHead.isActive ? <div key={index} className={classToApply+" headMainMenuBtn"}>{mainHead.optMenu}</div> : false }
        )
    )
}
const fncMainLogoText = (dataForLogoText, classToApplyToPic, classToApplyToText) => {
    return(
        dataForLogoText.hasLogo 
            ? <div className={classToApplyToPic}><img src={dataForLogoText.logoHozUrl} alt={dataForLogoText.alts}></img></div> 
            : <div className={classToApplyToText}>{dataForLogoText.name}</div>
    )
}
const HeadLogoMenuNav = (props) => {
    const { dataForHeadMain: { dataForHeadL, dataForHeadR } } = props
    const mainMenuActiveCount = dataForHeadR.filter((data) => data.isActive === true)
    let columns = mainMenuActiveCount.length
	return (
		<div className="headLogoMenuBar" style={{gridTemplateColumns: '1fr repeat(' + columns + ', max-content)'}}>
            { fncMainLogoText(dataForHeadL, CLASSIMGLOGOHEADLEFT, CLASSTOTEXTHEADLEFTUP) }
            { fncMainContentMenu(dataForHeadR, CLASSMAINRIGHT) }
		</div>
	)
}

export default HeadLogoMenuNav