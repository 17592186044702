import "./articules.css"

export function ArticuleOneColumn (props) {
    const { contentArticules } = props
    return (
        <>
            {
                contentArticules.map((infoArticule) => {
                    if (infoArticule.isHighLigth || !infoArticule.isActive) {
                        return null;
                    }
                    return(
                    <div className="articulesInfinites" key={infoArticule.index}>
                       
                        <div className="articuleInfiniteImg">
                            <img src={infoArticule.Image} alt="data" />
                        </div>
                        <div>
                            <h1>{infoArticule.title}</h1>
                            <h2>{infoArticule.subTitle}</h2>
                            <p>{infoArticule.contentArticule}</p>
                        </div>
                    </div>

                    )
                })
            }
        </>
    )
}
export function ArticuleTwoColumns(props) {
    const { contentArticules } = props;
    return (
        <>
            {contentArticules.map((dataArticule) => {
                if (!dataArticule.isHighLigth) {
                    return null;
                }
                return (
                    <div className="articuleHighLigth" key={dataArticule.index}>
                        <div className="articuleHighImg">
                            <img src={dataArticule.Image} alt="nada" />
                        </div>
                        <div>
                            <div>
                                <h1>{dataArticule.title}</h1>
                                <h3>{dataArticule.subTitle}</h3>
                            </div>
                            <div>
                                <p>{dataArticule.contentArticule}</p>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
