import HaedAllComponent from "./headMain"
import "./head.css"
function HeadView(props) {
    const { listDataSlider, listDataMenuHead } = props
    return (
        <HaedAllComponent 
            listDataMenuHead={listDataMenuHead}
            listDtSlider={listDataSlider}
        />
    )
}

export default function HeadComponent(props) {
    const {dtHeadComponent} = props
    const { webContent: { menus: { menu_head_rigth:dataForHeadR, menu_heead_left: dataForHeadL } } } = dtHeadComponent
    const { webContent: { slider: dataForSlider } } = dtHeadComponent
    const menusHead = { dataForHeadR, dataForHeadL }
    return <HeadView 
                listDataMenuHead={menusHead}
                listDataSlider={dataForSlider}
                />
}

