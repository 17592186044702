import { createSlice } from '@reduxjs/toolkit'

export const contentSlice = createSlice({
    name: 'content',
    initialState: {
        webContent: {}
    },
    reducers: {
        getContentWeb: (state, action) => {
            state.webContent = action.payload            
        }
        //Estudiar un poco mas
    }
})

export const { getContentWeb } = contentSlice.actions

export default contentSlice.reducer