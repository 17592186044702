import axios from 'axios'

const callGetAllData = async() => {
    let apiUrl = `${process.env.REACT_APP_BASE_DEV}${process.env.REACT_APP_API_URL}`
    let headersCustom = {
        "headers": {
            "x-api-key": process.env.REACT_APP_API_KEY,
        }
    }
    if(process.env.NODE_ENV !== "development"){
        apiUrl = "/api/call"
        headersCustom = ""
    }
    try { 
        const responseData = await axios.get(apiUrl, headersCustom)
        return responseData
    } catch (error) {
        console.log('Error', error)
    }

}

export { callGetAllData }